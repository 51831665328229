"use client";

import {Partial} from "@/components/partial/partial";
import {PartialPageConfig} from "@/libs/config/navigation.config";
import {useEffect} from "react";

type Props = {
  error: Error & {digest?: string};
  reset: () => void;
};

export default function Error({error, reset}: Props) {
  const config = PartialPageConfig.error;
  useEffect(() => {}, [error]);

  return (
    <Partial
      head={config.head}
      message={config.message}
      imageUrl={config.imageUrl}
      btnText={config.btnText}
      btnUrl={config.btnUrl}
    />
  );
}
