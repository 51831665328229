import {NeoButton} from "@/components/forms";
import {NeoDescription, NeoTitle} from "@/components/shared";
import Image from "next/image";
import React from "react";

interface PartialProps {
  imageUrl: string;
  head: string;
  message: string;
  btnText: string;
  btnUrl: string;
}

export const Partial: React.FC<PartialProps> = ({
  imageUrl,
  head,
  message,
  btnText,
  btnUrl
}) => {
  return (
    <div className="grid h-screen grid-cols-2 items-center justify-items-center gap-2">
      <div className="items-left flex flex-col justify-center gap-3">
        <NeoTitle message={head}></NeoTitle>
        <NeoDescription message={message} />
        <NeoButton className="mt-3 w-max min-w-40" size="xl" color="black">
          {btnText}
        </NeoButton>
      </div>
      <div>
        <Image
          src={imageUrl}
          width={600}
          height={600}
          alt="error"
          className="object-cover"
        />
      </div>
    </div>
  );
};
